<template>
	<v-app>
		<v-data-table :headers="headers" :items="users" :hide-default-footer="false" :items-per-page="50"
			sort-by="createdAt" sort-desc @click:row="handleClick"
			:footer-props="{ 'items-per-page-options': [15, 30, 50, 100, -1], 'items-per-page-text': '行/ページ:' }">
			<template v-slot:top>
				<v-toolbar flat>
					<v-toolbar-title>ユーザ一覧</v-toolbar-title>
					<v-dialog v-model="dialog" max-width="500px">
						<v-card>
							<v-card-title>
								<span class="headline">{{ formTitle }}</span>
							</v-card-title>

							<v-card-text>
								<v-container>
									<v-row>
										<v-col cols="12" sm="12" md="12">
											<v-select v-model="editedItem.projectId" :items="projects"
												outlined></v-select>
										</v-col>
									</v-row>
								</v-container>
							</v-card-text>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="blue darken-1" text @click="close">
									キャンセル
								</v-btn>
								<v-btn color="blue darken-1" text @click="save">
									保存
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
					<v-dialog v-model="dialogDelete" max-width="500px">
						<v-card>
							<v-card-title class="headline">本当にこのユーザを削除しますか？</v-card-title>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="blue darken-1" text @click="closeDelete">キャンセル</v-btn>
								<v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-toolbar>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon small @click="editItem(item)" class="edit">
					mdi-pencil
				</v-icon>
				<v-icon small @click="deleteItem(item)" class="remove">
					mdi-delete
				</v-icon>
			</template>
		</v-data-table>
		<v-footer>
			©2021 <a href="https://www.vegetalia.co.jp/">vegetalia</a>, inc.
		</v-footer>
	</v-app>
</template>

<script>
import axios from "axios";

export default {
	components: {},
	data: () => ({
		dialog: false,
		dialogDelete: false,
		headers: [
			{ text: "名前", value: "name" },
			{ text: "メール", value: "email" },
			{ text: "ログイン", value: "loginType" },
			{ text: "プロジェクトID", value: "projectId" },
			{ text: "プロジェクト名称", value: "projectName" },
			{ text: "権限", value: "role" },
			{ text: "アクティベート", value: "activate" },
			{ text: "最終ログイン", value: "createdAt" },
			{ text: "アクション", value: "actions", sortable: false },
		],
		users: [],
		projects: [],
		editedIndex: -1,
		editedItem: {
			id: "",
			name: "",
			email: "",
			loginType: "",
			projectId: "",
			role: "",
			activate: "",
			createdAt: "",
		},
		defaultItem: {
			id: "",
			name: "",
			email: "",
			loginType: "",
			projectId: "",
			role: "",
			activate: "",
			createdAt: "",
		},
	}),
	computed: {
		formTitle() {
			return "プロジェクトを変更";
		},
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
	},
	created() {
		axios.defaults.headers.common["Authorization"] =
			"Bearer " + this.$store.state.auth.data.token;
		this.initialize();
	},
	methods: {
		initialize() {
			this.showLoading();
			axios
				.get(
					"https://" +
					this.config.defaultOriginString +
					"/api/admin-users"
				)
				.then((res) => {
					this.hideLoading();
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						this.users = res.data.users;
						this.projects = res.data.projects.map(function (value) {
							return { text: value.name, value: value.id };
						});
						console.log("Success: " + JSON.stringify(res.data));
					} else {
						console.log("Fail: " + JSON.stringify(res));
					}
				})
				.catch((error) => {
					this.hideLoading();
					console.log(error);
				});
		},
		editItem(item) {
			this.editedIndex = this.users.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},
		deleteItem(item) {
			this.editedIndex = this.users.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},
		deleteItemConfirm() {
			this.showLoading();
			const params = {
				id: this.editedItem.id,
				delete: "true",
			};
			axios
				.post(
					"https://" +
					this.config.defaultOriginString +
					"/api/admin-update-user",
					params
				)
				.then((res) => {
					this.hideLoading();
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						this.users.splice(this.editedIndex, 1);
						this.closeDelete();
						console.log("Success: " + JSON.stringify(res.data));
					} else {
						console.log("Fail: " + JSON.stringify(res));
					}
				})
				.catch((error) => {
					this.hideLoading();
					console.log(error);
				});
		},
		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},
		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},
		save() {
			if (this.editedIndex > -1) {
				this.showLoading();
				const params = {
					id: this.editedItem.id,
					projectId: this.editedItem.projectId,
				};
				const index = this.editedIndex;
				const item = this.editedItem;
				axios
					.post(
						"https://" +
						this.config.defaultOriginString +
						"/api/admin-update-user",
						params
					)
					.then((res) => {
						this.hideLoading();
						// 成功した場合
						if (res && res.data && res.data.status == "success") {
							console.log("Success: " + JSON.stringify(res.data));
							this.$router.go({ path: this.$router.currentRoute.path, force: true });
						} else {
							console.log("Fail: " + JSON.stringify(res));
						}
					})
					.catch((error) => {
						this.hideLoading();
						console.log(error);
					});
			} else {
				this.users.push(this.editedItem);
			}
			this.close();
		},
		showLoading: function () {
			this.$emit("loadShowHide", true);
		},
		hideLoading: function () {
			this.$emit("loadShowHide", false);
		},
		handleClick(value, e) {
			console.log(e.index);
			e.select(!e.isSelected);
		},
	},
};
</script>

<style></style>
